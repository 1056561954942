import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Language.scss';
import Header from '../../Header';
import 'react-dropdown/style.css';
import { Check } from 'react-feather';

function Language() {
    const { t, i18n } = useTranslation();
    const [lang, setLang] = useState(localStorage.getItem("language"))

    const handleChangeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        localStorage.setItem("language", lang)
        // history.push("/settings");
    };

    // useEffect(() => {

    // }, [i18n.language])

    return (
        <>
            <Header pageName={t('setting.title')} headerType="second" />
            <li className="li-menu" onClick={() => handleChangeLanguage('en')}>
                English
                    {(i18n.language === 'en' && <div><Check /></div>)}
            </li>
            <li className="li-menu" onClick={() => handleChangeLanguage('zh')}>
                简体中文
                    {(i18n.language === 'zh' && <div><Check /></div>)}
            </li>
        </>
    )

}

export default Language;