import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Scam.scss';


function Scam() {

    useEffect(() => {
       
    }, [])

    return (
        <></>
    )
}

export default Scam;