import React from 'react';
import { useTranslation } from 'react-i18next';
import './Discover.scss';
import Container from 'react-bootstrap/Container';
import Navbar from '../../Navbar';
import { Link } from 'react-router-dom';
import 'react-dropdown/style.css';

function Discover() {
    const { t } = useTranslation();
    // useEffect(() => {

    // }, [i18n.language])

    return (
        <>
            <Navbar pageTitle={t('discover.title')} />
            <Container>
                <div className="discover-panel">
                    <div className="list-page">
                        <li><Link to="/categories/1">{t('directory.title')}</Link></li>
                    </div>
                    {/* <div className="list-page">
                        <li><Link to="/influencers/1">{t('public_figure.title')}</Link></li>
                    </div> */}
                    {/* <div className="list-page">
                        <li>{t('scam_project.title')}</li>
                    </div> */}
                     <div className="list-page">
                        <li><a href="https://8dhvguuksjk.typeform.com/to/v6wYwYrq" target="_blank">{t('contact')}</a></li>
                    </div>
                </div>
            </Container>
        </>
    )

}

export default Discover;