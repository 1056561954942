import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.scss';
import CONSTANTS from '../../constants.json';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/logox150.png';


function Sidebar() {
    const { i18n } = useTranslation();
    const [error, setError] = useState(null)
    const [categories, setCategories] = useState([])
    const [activeCategoryId, setActiveCategoryId] = useState('')

    useEffect(() => {
        setActiveCategoryId(window.location.pathname.split("/").pop())
        fetch(
            CONSTANTS.API_URL + `categories`,
            // `https://api.hollo.app/api/v1/categories`,
            {
                method: 'GET',
                headers: {
                    'Accept-Language': localStorage.getItem("language")
                }
            }
        )
            .then(res => res.json())
            .then(
                (response) => {
                    setCategories(response.data)
                    // setActiveCategoryId(window.location.pathname.split("/").pop())
                    // alert(activeCategoryId)
                },
                (error) => {
                    setError(error)
                }
            )
    }, [i18n.language])

    if (window.innerWidth > 768) { // Windows width
        return (
            <div className="sidebar">
                <div className="background"></div>

                <div className="logo-panel">
                    <h6 className="logo-wording">Blocket</h6>
                </div>
                {/* <div className="logo-panel">
                    <img src={logo} className="sidebar-logo" alt="" />
                </div> */}
                {categories.map(category => (
                    // <li key={category.id}><Link to={"/categories/" + category.id} key={category.id} onClick={() => setActiveCategoryId(category.id)}><span className="category-icon-box"><img src={`/images/${category.icon}`} alt="" /></span>{category.name}</Link></li>
                    <li key={category.id}
                        className={
                            // link.className +
                            (category.id === activeCategoryId ? "active" : "")
                        }>
                        <Link to={"/categories/" + category.id} key={category.id} onClick={() => setActiveCategoryId(category.id)}>
                            <span className="category-icon-box"><img src={`/images/${category.icon}`} alt="" /></span>
                            {category.name}
                        </Link>
                    </li>
                ))}
            </div>
        )
    } else {
        return (
            <div className="sidebar">
                <div className="logo-panel">
                    <img src={logo} className="sidebar-logo" alt="" />
                </div>
                {categories.map(category => (
                    <li key={category.id}><Link to={"/categories/" + category.id} key={category.id}><span className="category-icon-box"><img src={`/images/${category.icon}`} alt="" /></span>{category.name}</Link></li>
                ))}
            </div>
        )
    }
}

export default Sidebar;