import React, { useEffect, useState } from 'react';
import './CategoryTab.scss';


function CategoryTab() {
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [categories, setCategories] = useState([])

    useEffect(() => {
        fetch(
            `http://127.0.0.1:8000/api/v1/categories`,
            {
                method: 'GET'
                // headers: new Headers({
                //     Accept: "application/vnd.github.cloak-preview"
                // })
            }
        )
            .then(res => res.json())
            .then(
                (response) => {
                    setIsLoaded(true)
                    setCategories(response.data)
                },
                (error) => {
                    setIsLoaded(true)
                    setError(error)
                }
            )

    }, [])

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <div>
                {categories.map(category => (
                    <li key={category.id} className="category-tab">
                        {category.name}
                    </li>
                ))}
            </div>
        );
    }

}

export default CategoryTab;