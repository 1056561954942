import React, { useEffect, useState } from 'react';
import './PlatformDetails.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

function PlatformDetails(props) {
    // const platform = props.match.params.platform
    const [setError] = useState(null)
    const [platform, setPlatform] = useState([])

    useEffect(() => {
        fetch(
            `http://127.0.0.1:8000/api/v1/platforms/` + props.match.params.platform + `/info`,
            {
                method: 'GET'
            }
        )
            .then(res => res.json())
            .then(
                (response) => {
                    setPlatform(response.data)
                },
                (error) => {
                    setError(error)
                }
            )
    }, [])

    return (
        <Container>
            <Row>
                <Col md={8}>
                    <h2>{platform.name}</h2>
                    <p>{platform.description}</p>
                </Col>
                <Col md={4}>
                    <div class="card social-card">
                        aaa
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default PlatformDetails;