import React, { useEffect, useState } from 'react';
import './Futurist.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { Facebook, Twitter, Linkedin, Youtube } from 'react-feather';

function Futurist(props) {
    const { i18n } = useTranslation();
    const [error, setError] = useState(null)
    const [futurists, setFuturist] = useState([])

    useEffect(() => {
        fetch(
            // `http://127.0.0.1:8000/api/v1/influence_roles/` + props.influenceRole + `/futurists`,
            `https://api.hollo.app/api/v1/influence_roles/` + props.influenceRole + `/futurists`,
            {
                method: 'GET',
                headers: {
                    'Accept-Language': localStorage.getItem("language")
                }
            }
        )
            .then(res => res.json())
            .then(
                (response) => {
                    setFuturist(response.data)
                },
                (error) => {
                    setError(error)
                }
            )
        // }, [props.category, i18n.language])
    }, [props.influenceRole, i18n.language])

    return (
        <>
            <div className="futurist-panel">
                <Row>
                    {futurists.map(futurist => (
                        <Col md={3} key={futurist.id}>
                            <div className={'card card-futurist'} key={futurist.id}>
                                {futurist.create_coin &&
                                    <div className="create-coin">{futurist.create_coin}</div>
                                }
                                <div className="profile-picture"><img src={`/images/${futurist.profile_picture}`} alt={futurist.name} /></div>
                                <h6>{futurist.name}</h6>
                                <p className="ellipsis ellipsis-2">{futurist.biography}</p>
                                <div className="link-panel">
                                    {futurist.facebook &&
                                        <li><a href={futurist.facebook} target="_blank" rel="noreferrer"><Facebook /></a></li>
                                    }
                                    {futurist.twitter &&
                                        <li><a href={futurist.twitter} target="_blank" rel="noreferrer"><Twitter /></a></li>
                                    }
                                    {futurist.linkedin &&
                                        <li><a href={futurist.linkedin} target="_blank" rel="noreferrer"><Linkedin /></a></li>
                                    }
                                    {futurist.youtube &&
                                        <li><a href={futurist.youtube} target="_blank" rel="noreferrer"><Youtube /></a></li>
                                    }
                                </div>
                                {/* <div>
                                    {futurist.influence_roles.map(influence_role => (
                                        <span className="category-tag" key={influence_role.id}>{influence_role.name}</span>
                                    ))}
                                </div> */}
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
            <div className="clear"></div>
        </>
    )
}

export default Futurist;