import React from 'react';
import { Link } from 'react-router-dom';
import './MobileMenu.scss';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Home, Compass, Settings } from 'react-feather';

function MobileMenu() {

    return (
        <div className="mobile-menu">
            <Container>
                <Row>
                    <Col md={4}><Link to="/categories/1"><Home /></Link></Col>
                    <Col md={4}><Link to="/discover"><Compass /></Link></Col>
                    <Col md={4}><Link to="/settings"><Settings /></Link></Col>
                </Row>
            </Container>
        </div>
    )
}

export default MobileMenu;