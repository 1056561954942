import React, { useEffect, useState } from 'react';
import './InfluenceRole.scss';
import Container from 'react-bootstrap/Container';
import Navbar from '../../Navbar';
import SidebarInfluenceRole from '../../SidebarInfluenceRole';
import Futurist from '../../Futurist';
import { useTranslation } from 'react-i18next';

function InfluenceRole(props) {
    const { t, i18n } = useTranslation();
    const [width, setWidth] = useState(window.innerWidth)
    const [influenceRole, setInfluenceRole] = useState(props.match.params.influenceRole)

    useEffect(() => {
        setInfluenceRole(props.match.params.influenceRole)

        function handleResize() {
            setWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)
    }, [props.match.params.influenceRole, i18n.language])

    if (window.innerWidth > 768) {
        return (
            <>
                <SidebarInfluenceRole />
                <div className="content-panel">
                    <Container>
                        <Futurist influenceRole={influenceRole} />
                    </Container>
                </div>
            </>
        )
    } else {
        return (
            <>
                <Navbar pageTitle={t('public_figure.title')} />
                <div className="sidebar-wrapper">
                    <SidebarInfluenceRole />
                </div>
                <Container>
                    <Futurist influenceRole={influenceRole} />
                </Container>
            </>
        )
    }
}

export default InfluenceRole;