import React, { useState } from 'react';
import './Navbar.scss';
import { Link } from 'react-router-dom';
import ThemeToggle from '../ThemeToggle';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { Globe } from 'react-feather';

function Navbar(props) {
    const { t, i18n } = useTranslation();
    const options = [
        { value: 'en', label: 'English' },
        { value: 'zh', label: '简体中文' },
    ];

    if (localStorage.getItem("language") === null) {
        localStorage.setItem("language", 'en')
    }
    const [selectedLanguage] = useState(localStorage.getItem('language'))

    const handleChangeLanguage = (lang) => {
        i18n.changeLanguage(lang.value);
        localStorage.setItem("language", lang.value)
    };

    // useEffect(() => {

    // }, [i18n.language])

    if (window.innerWidth > 768) {
        return (
            <>
                <div className="navbar">
                    <Container>
                        <div className="left-menu">
                            {/* <Link to='/'><img src={logo} alt='' className="logo" /> <span className="temp">Blocket</span></Link> */}
                            <ul>
                                <li><Link to="/categories/1">{t('directory.title')}</Link></li>
                                {/* <li>NFT</li>
                                <li><Link to="/influencers/1">{t('public_figure.title')}</Link></li> */}
                                {/* <li>Scams</li> */} 
                                <li><a href="https://8dhvguuksjk.typeform.com/to/v6wYwYrq" target="_blank">Contact</a></li>
                            </ul>
                        </div>
                        <div className="right-menu">
                            <ul>
                                <li className="li-language">
                                    {/* {(i18n.language == 'zh' && <div><Check /></div>)} */}
                                    <Globe />
                                    <Dropdown
                                        onChange={handleChangeLanguage}
                                        className="dd-language"
                                        options={options}
                                        value={selectedLanguage}
                                    />
                                </li>
                                <li><ThemeToggle /></li>
                            </ul>
                        </div>
                    </Container>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="navbar">
                    <Container>
                        <h6 className="logo-wording">Blocket</h6>
                        {/* <h1>{props.pageTitle}</h1> */}
                    </Container>
                </div>
            </>
        )
    }
}

export default Navbar;