import React from 'react';
import { useHistory } from "react-router-dom";
import './BackHeader.scss';
import Container from 'react-bootstrap/Container';
import 'react-dropdown/style.css';
import { ArrowLeft } from 'react-feather';

function BackHeader(props) {
    let history = useHistory();
    // const handleBack = () => {
    //     alert()
    // }

    return (
        <>
            <div className="back-header">
                <Container>
                    <div className="back-arrow" onClick={() => history.goBack()}><ArrowLeft /></div>
                    <h6>{props.pageName}</h6>
                </Container>
            </div>
        </>
    )

}

export default BackHeader;