import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { CategoryTab } from '../../../Category';

function PlatformList() {
    const [error, setError] = useState(null)
    const [platforms, setPlatforms] = useState([])

    useEffect(() => {
        fetch(
            `http://127.0.0.1:8000/api/v1/categories/1/platforms`,
            {
                method: 'GET'
            }
        )
            .then(res => res.json())
            .then(
                (response) => {
                    setPlatforms(response.data)
                    console.log(response.data)
                },
                (error) => {
                    setError(error)
                }
            )
    }, [])

    if (error) {

    } else {
        return (
            <Container>
                <h5>Platforms</h5>
                <CategoryTab />
                {platforms.map(platform => (
                    <Link to={`/platforms/${platform.id}`}>{platform.name}</Link>
                ))}
            </Container>
        )
    }

}

export default PlatformList;