import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Sidebar from '../../Sidebar';
import './Home.scss';
import tempLogo from '../../../assets/images/logo_binance.png';

export default class Home extends React.Component {
    // const [error, setError] = useState(null)
    // const [categories, setCategories] = useState([])

    state = {
        categories: []
    }

    async componentDidMount() {

        // const { t, i18n } = useTranslation();
        const url = 'http://127.0.0.1:8000/api/v1/platforms';
        const response = await fetch(url, {
            headers: {
                'Accept-Language': 'en',
            }
        });
        const data = await response.json();
        this.setState(
            {
                categories: data.data
            }
        )
    }

    render() {
        return (
            <>
                <Container>
                    {/* <h1>Directory</h1> */}
                    {/* <h1>{i18n.language}</h1> */}
                    <Row>
                        <Sidebar />
                    </Row>
                    <Row>
                        {
                            this.state.categories.map((category) =>
                                <Col md={4}>
                                    <div class="category-panel">
                                        <div className="title-panel">
                                            <h3 key={category.id}>
                                                {category.name}
                                            </h3>
                                            <span>See all</span>
                                        </div>
                                        <div>
                                            {
                                                category.platforms.map((platform) =>
                                                    <div key={platform.id} className="platform-list">
                                                        <Link to={"/" + category.name + "/" + platform.id}>
                                                            <div className="left-panel">
                                                                <div className="logo-box"><img src={tempLogo} alt={platform.name} /></div>
                                                            </div>
                                                            <div className="right-panel">
                                                                <div className="platform-summary">
                                                                    <h6>{platform.name}</h6>
                                                                    <p className="ellipsis ellipsis-2">{platform.description}</p>
                                                                </div>
                                                            </div>

                                                            <div className="clear"></div>
                                                        </Link>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </Col>
                            )
                        }
                    </Row>
                </Container>
            </>
        )
    }

    // useEffect(() => {
    //     fetch(
    //         `http://127.0.0.1:8000/api/v1/platforms`,
    //         {
    //             method: 'GET'
    //             // headers: new Headers({
    //             //     Accept: "application/vnd.github.cloak-preview"
    //             // })
    //         }
    //     )
    //         .then(res => res.json())
    //         .then(
    //             (response) => {
    //                 setCategories(response.data)
    //             },
    //             (error) => {
    //                 setError(error)
    //             }
    //         )

    // }, [])


    // return (
    //     <Container>
    //         <h2>Directory</h2>
    //         <div>
    //             {categories.map((category, i) => (
    //                 <div key={category.id}>
    //                     {category.name}
    //                 </div>
    //                 {
    //                     category.platforms.map((c, i) => (
    //                         <div key={i}>
    //                             {c.name}
    //                         </div>
    //                     ))
    //                 }
    //             ))}
    //         </div>
    //     </Container>
    // )
}

// export default Home;