import React, { useEffect, useState } from 'react';
import './Platform.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CONSTANTS from '../../constants.json';
import { useTranslation } from 'react-i18next';
import { Globe, Facebook, Twitter } from 'react-feather';
import { FaTelegramPlane, FaDiscord } from 'react-icons/fa';

function Platform(props) {
    const { i18n } = useTranslation();
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true);
    const [platforms, setPlatforms] = useState([])

    useEffect(() => {
        setLoading(true)
        fetch(
            CONSTANTS.API_URL + `categories/` + props.category + `/platforms`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'text/plain',
                    'Accept-Language': localStorage.getItem("language")
                }
            }
        )
            .then(res => res.json())
            .then(
                (response) => {
                    setPlatforms(response.data)
                    setLoading(false)
                },
                (error) => {
                    setError(error)
                }
            )
        // }, [props.category, i18n.language])
    }, [props.category, i18n.language])

    return (
        <>
            {
                loading === true
                    ?
                    <Row>
                        {[...Array(3)].map((x, i) =>
                            <Col md={4} key={i}>
                                <div className={'card platform-list loading'}>
                                    <div className="left-panel">
                                        <div className="logo-box"></div>
                                    </div>
                                    <div className="right-panel">
                                        <div className="platform-summary">
                                            <h6></h6>
                                            <p className="ellipsis ellipsis-2"></p>
                                            <div className="link-panel">
                                                <div></div>
                                            </div>
                                            <span className="category-tag"></span>
                                        </div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </Col>
                        )}
                    </Row>
                    :
                    <>
                        {/* <h1>{i18n.language}</h1> */}
                        {/* <div className="banner"></div> */}
                        <div className="platform-panel">
                            {/* <h3>Category</h3>
                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit. Duis dapibus rutrum facilisis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam tristique libero eu nibh porttitor amet fermentum. Nullam venenatis erat id vehicula ultrices sed ultricies condimentum.</p> */}
                            <Row>
                                {platforms.map(platform => (
                                    <Col md={4} key={platform.id}>
                                        <a href={platform.website} target="_blank">
                                            <div className={'card platform-list'} key={platform.id}>
                                                <div className="left-panel">
                                                    <div className="logo-box"><img src={`/images/${platform.logo}`} alt={platform.name} /></div>
                                                </div>
                                                <div className="right-panel">
                                                    <div className="platform-summary">
                                                        <h6>{platform.name}</h6>
                                                        <p className="ellipsis ellipsis-2">{platform.description}</p>
                                                        <div className="link-panel">
                                                            {platform.website &&
                                                                <li><a href={platform.website} target="_blank" rel="noreferrer"><Globe /></a></li>
                                                            }
                                                            {platform.facebook &&
                                                                <li><a href={platform.facebook} target="_blank" rel="noreferrer"><Facebook /></a></li>
                                                            }
                                                            {platform.twitter &&
                                                                <li><a href={platform.twitter} target="_blank" rel="noreferrer"><Twitter /></a></li>
                                                            }
                                                            {platform.telegram &&
                                                                <li><a href={platform.telegram} target="_blank" rel="noreferrer">
                                                                    <FaTelegramPlane className="fa-icon" />
                                                                </a></li>
                                                            }
                                                            {platform.discord &&
                                                                <li><a href={platform.discord} target="_blank" rel="noreferrer">
                                                                    <FaDiscord className="fa-icon" />
                                                                </a></li>
                                                            }
                                                        </div>
                                                        <div>
                                                            {platform.categories.map(category => (
                                                                <span className="category-tag" key={category.id}>{category.name}</span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="clear"></div>
                                            </div>
                                        </a>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                        <div className="clear"></div>
                    </>

            }

        </>
    )
}

export default Platform;