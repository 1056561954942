import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './Setting.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Navbar from '../../Navbar';
import 'react-dropdown/style.css';

function Setting() {
    const { t } = useTranslation();
    const [languageText, setLanguageText] = useState()
    const [currentLanguage] = useState(localStorage.getItem("language"))
    const [themeText] = useState(localStorage.getItem("theme"))

    useEffect(() => {
        
        if(currentLanguage === 'en'){
            setLanguageText('English')
        }else if(currentLanguage === 'zh'){
            setLanguageText('简体中文')
        }else{
            setLanguageText('简体中文')
        }

    }, [])

    return (
        <>  
            <Navbar pageTitle={t('setting.title')} />
            <Container>
                <div className="discover-panel">
                    <div className="list-page">
                        <li>
                            <Link to='/language'>
                                <Row>
                                    <Col md={6}>
                                    {t('setting.language')}
                                </Col>
                                    <Col md={6}>
                                        <label>{languageText}</label>
                                    </Col>
                                </Row>
                            </Link>
                        </li>
                    </div>
                    <div className="list-page">
                        <li>
                            <Link to='/theme'>
                                <Row>
                                    <Col md={6}>
                                    {t('setting.theme')}
                                </Col>
                                    <Col md={6}>
                                        <label>{t('setting.' + themeText)}</label>
                                    </Col>
                                </Row>
                            </Link>
                        </li>
                    </div>
                </div>
            </Container>
        </>
    )

}

export default Setting;