import React from 'react';
import { useHistory } from "react-router-dom";
import './Header.scss';
import Container from 'react-bootstrap/Container';
import 'react-dropdown/style.css';
import { ArrowLeft } from 'react-feather';

function Header(props) {
    let history = useHistory();

    if (props.headerType === 'first') {
        return (
            <>
                <div className="back-header">
                    <Container>
                        <div className="back-arrow" onClick={() => history.goBack()}><ArrowLeft /></div>
                        <h6>{props.pageName}</h6>
                    </Container>
                </div>
            </>
        )
    }else{
        return (
            <>
                <div className="back-header">
                    <Container>
                        <div className="back-arrow" onClick={() => history.goBack()}><ArrowLeft /></div>
                        <h6>{props.pageName}</h6>
                    </Container>
                </div>
            </>
        )
    }

}

export default Header;