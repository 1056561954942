import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './SidebarInfluenceRole.scss';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/logox150.png';


function SidebarInfluenceRole() {
    const { i18n } = useTranslation();
    const [error, setError] = useState(null)
    const [influnceRoles, setInflunceRoles] = useState([])
    const [activeCategoryId, setActiveCategoryId] = useState('')

    useEffect(() => {
        fetch(
            // `http://127.0.0.1:8000/api/v1/influence_roles`,
            `https://api.hollo.app/api/v1/influence_roles`,
            {
                method: 'GET',
                headers: {
                    'Accept-Language': localStorage.getItem("language")
                }
            }
        )
            .then(res => res.json())
            .then(
                (response) => {
                    setInflunceRoles(response.data)
                },
                (error) => {
                    setError(error)
                }
            )
    }, [i18n.language])

    if (window.innerWidth > 768) { // Windows width
        return (
            <div className="sidebar">
                <div className="background"></div>
                <div className="logo-panel">
                    <img src={logo} className="sidebar-logo" alt="" />
                </div>
                {influnceRoles.map(influenceRole => (
                    <li key={influenceRole.id}
                        className={
                            // link.className +
                            (influenceRole.id === activeCategoryId ? "active" : "")
                        }>
                        <Link to={"/influencers/" + influenceRole.id} key={influenceRole.id} onClick={() => setActiveCategoryId(influenceRole.id)}>
                            <span className="category-icon-box"><img src={`/images/${influenceRole.icon}`} alt="" /></span>
                            {influenceRole.name}
                        </Link>
                    </li>
                ))}
            </div>
        )
    } else {
        return (
            <div className="sidebar">
                <div className="logo-panel">
                    <img src={logo} className="sidebar-logo" alt="" />
                </div>
                {influnceRoles.map(influenceRole => (
                    <li key={influenceRole.id}><Link to={"/influencers/" + influenceRole.id} key={influenceRole.id}><span className="category-icon-box"><img src={`/images/${influenceRole.icon}`} alt="" /></span>{influenceRole.name}</Link></li>
                ))}
            </div>
        )
    }
}

export default SidebarInfluenceRole;