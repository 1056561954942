import React, { useEffect, useState } from 'react';
import './Category.scss';
import Container from 'react-bootstrap/Container';
import Navbar from '../../Navbar';
import Sidebar from '../../Sidebar';
import Platform from '../../Platform';
import { useTranslation } from 'react-i18next';

function Category(props) {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [width, setWidth] = useState(window.innerWidth)
    const [category, setCategory] = useState(props.match.params.category);
    // const [error, setError] = useState(null)
    // const [platforms, setPlatforms] = useState([])

    useEffect(() => {
        setCategory(props.match.params.category)

        function handleResize() {
            setWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)
    }, [props.match.params.category, i18n.language])

    if (window.innerWidth > 768) {
        return (
            <>
                <Sidebar />
                <div className="content-panel">
                    <Container>
                        <Platform category={category} />
                    </Container>
                </div>
            </>
        )
    } else {
        return (
            <>
                <Navbar pageTitle={t('directory.title')} />
                <div className="sidebar-wrapper">
                    <Sidebar />
                </div>
                <Container>
                    <Platform category={category} />
                </Container>
            </>
        )
    }
}

export default Category;