// import logo from './logo.svg';
import './App.scss';
import React, { useEffect, useState } from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomePage from './components/pages/Home';
import { PlatformList } from './components/pages/Platform';
import Category from './components/pages/Category';
import InfluenceRole from './components/pages/InfluenceRole';
import Discover from './components/pages/Discover';
import Setting from './components/pages/Setting';
import Scam from './components/pages/Scam';
import Language from './components/pages/Language';
import ThemeMode from './components/pages/ThemeMode';
import MobileMenu from './components/MobileMenu';

function App() {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {

    function handleResize() {
      setWidth(window.innerWidth)
    }

    const currentTheme = localStorage.getItem('theme');

    if (currentTheme === 'light' || currentTheme === null) {
      document.body.classList.remove('dark-mode');
      document.body.classList.remove('neon-mode');
    } else if (currentTheme === 'dark') {
      document.body.classList.remove('neon-mode');
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
      document.body.classList.add('neon-mode');
    }

    window.addEventListener('resize', handleResize)
  }, [])

  if (width > 768) {
    return (
      <>
        <Router>
          <Navbar />
          <Switch>
            <Route path='/' component={HomePage} exact />
            <Route path='/discover' component={Discover} exact />
            <Route path='/scams' component={Scam} exact />
            <Route
              render={(props) => <Category {...props} />}
              path='/categories/:category'
              component={Category}
            />
            <Route path='/platforms' component={PlatformList} exact />
            <Route
              render={(props) => <InfluenceRole {...props} />}
              path='/influencers/:influenceRole'
              component={InfluenceRole}
            />
            {/* <Route path='/crypto-celebrities' component={CrytoCelebrities} exact /> */}
            {/* <Route path='/:category/:platform' component={PlatformDetails} exact /> */}
          </Switch>
        </Router>
      </>
    );
  } else {
    return (
      <>
        <Router>
          {/* <Navbar pageTitle="Directory" /> */}
          <MobileMenu />
          {/* <h1>{location}</h1> */}
          <Switch>
            <Route path='/' component={HomePage} exact />
            <Route path='/discover' component={Discover} exact />
            <Route path='/settings' component={Setting} exact />
            <Route path='/language' component={Language} exact />
            <Route path='/theme' component={ThemeMode} exact />
            <Route
              render={(props) => <Category {...props} />}
              path='/categories/:category'
            />
            <Route
              render={(props) => <InfluenceRole {...props} />}
              path='/influencers/:influenceRole'
              component={InfluenceRole}
            />
            <Route path='/platforms' component={PlatformList} exact />
          </Switch>
        </Router>
      </>
    );
  }

}

export default App;
