import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ThemeMode.scss';
import BackHeader from '../../BackHeader';
import 'react-dropdown/style.css';
import { Check } from 'react-feather';

function ThemeMode() {
    const { t } = useTranslation();
    const [theme, setTheme] = useState(localStorage.getItem('theme'));

    // const handleChangeTheme = (theme) => {
    //     localStorage.setItem("theme", theme)
    //     setTheme(theme)
    // }
    const handleChangeTheme = (theme) => {
        localStorage.setItem('theme', theme);
        setTheme(theme);
        // if (localStorage.getItem('theme') === 'light' || localStorage.getItem('theme') === null) {
        //     setTheme('dark');
        //     // alert(theme)
        //     localStorage.setItem('theme', 'dark');
        // } else if (localStorage.getItem('theme') === 'dark') {
        //     setTheme('neon');
        //     localStorage.setItem('theme', 'neon');
        // } else {
        //     setTheme('light');
        //     localStorage.setItem('theme', 'light');
        // }

    }

    useEffect(() => {
        const currentTheme = localStorage.getItem('theme');
        if (currentTheme === null) {
            setTheme('dark');
            localStorage.setItem('theme', 'dark');
            const currentTheme = 'dark'
        } else {
            setTheme(localStorage.getItem('theme'));
        }

        if (currentTheme === 'light' || currentTheme === null) {
            document.body.classList.remove('dark-mode');
            document.body.classList.remove('neon-mode');
        } else if (currentTheme === 'dark') {
            document.body.classList.remove('neon-mode');
            document.body.classList.add('dark-mode');
        } else {
            document.body.classList.remove('dark-mode');
            document.body.classList.add('neon-mode');
        }
    }, [theme]);

    return (
        <>
            <BackHeader pageName={t('setting.theme')} />
            <li className="li-menu" onClick={() => handleChangeTheme('light')}>
                <label>{t('setting.light')}</label>
                {(theme === 'light' && <div><Check /></div>)}
            </li>
            <li className="li-menu" onClick={() => handleChangeTheme('dark')}>
                <label>{t('setting.dark')}</label>
                {(theme === 'dark' && <div><Check /></div>)}
            </li>
            <li className="li-menu" onClick={() => handleChangeTheme('neon')}>
                <label>{t('setting.neon')}</label>
                {(theme === 'neon' && <div><Check /></div>)}
            </li>
        </>
    )

}

export default ThemeMode;