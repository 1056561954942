import React, { useEffect, useState } from 'react';
import './ThemeToggle.scss';
import { Moon, Sun } from 'react-feather';
// import neon from '../../../public/images/icons/neon.png';

function ThemeToggle() {
    const [theme, setTheme] = useState();

    const changeTheme = () => {

        if (localStorage.getItem('theme') === 'light' || localStorage.getItem('theme') === null) {
            setTheme('dark');
            // alert(theme)
            localStorage.setItem('theme', 'dark');
        } else if (localStorage.getItem('theme') === 'dark') {
            setTheme('neon');
            localStorage.setItem('theme', 'neon');
        } else {
            setTheme('light');
            localStorage.setItem('theme', 'light');
        }

    }

    useEffect(() => {
        const currentTheme = localStorage.getItem('theme');
        if (currentTheme === null) {
            setTheme('dark');
            localStorage.setItem('theme', 'dark');
            const currentTheme = 'dark'
        } else {
            setTheme(localStorage.getItem('theme'));
        }

        if (currentTheme === 'light' || currentTheme === null) {
            document.body.classList.remove('dark-mode');
            document.body.classList.remove('neon-mode');
        } else if (currentTheme === 'dark') {
            document.body.classList.remove('neon-mode');
            document.body.classList.add('dark-mode');
        } else {
            document.body.classList.remove('dark-mode');
            document.body.classList.add('neon-mode');
        }
        // alert(currentTheme)
    }, [theme]);

    return (
        <>

            {(theme === 'light' && <div><Moon onClick={changeTheme} /></div>)}
            {(theme === null && <div><Moon onClick={changeTheme} /></div>)}
            {/* {(theme === 'dark' && <div><label onClick={changeTheme} className="neon-wording">Neon</label></div>)} */}
            {(theme === 'dark' && <div><img src="/images/icons/neon.png" onClick={changeTheme} className="neon-wording" alt="" /></div>)}
            {(theme === 'neon' && <div><Sun onClick={changeTheme} /></div>)}
        </>
    )
}

export default ThemeToggle;